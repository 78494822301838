import classnames from 'classnames';
import React, { forwardRef } from 'react';
import './index.scss';

export enum ButtonTypes {
  Primary = 'btn-primary',
  PrimaryRoman = 'btn-primary--roman',
  PrimaryInfo = 'btn-primary btn-primary--info',
  PrimarySuccess = 'btn-primary btn-primary--success',
  PrimaryWarning = 'btn-primary btn-primary--warning',
  PrimaryDanger = 'btn-primary btn-primary--danger',
  Secondary = 'btn-secondary',
  BlueOutlined = 'btn-blue btn-blue--outlined',
  SecondaryInfo = 'btn-secondary btn-secondary--info',
  SecondarySuccess = 'btn-secondary btn-secondary--success',
  SecondaryWarning = 'btn-secondary btn-secondary--warning',
  SecondaryDanger = 'btn-secondary btn-secondary--danger',
  SecondaryNight = 'btn-secondary btn-secondary--night',
  White = 'btn-white',
  WhiteStormOutlined = 'btn-white btn-white--storm-outlined',
  WhiteIronOutlined = 'btn-white btn-white--iron-outlined',
  WhitePlain = 'btn-white btn-white--plain',
  Tertiary = 'btn-tertiary',
  Link = 'btn-link',
  LinkActive = 'btn-link btn-link--active',
  Simple = 'btn-simple',
  Reset = 'btn-reset',
  Athens = 'btn-athens',
  AthensOpen = 'btn-athens--open',
  Storm = 'btn-storm',
  Fountain = 'btn-fountain',
  SimpleAccent = 'btn-simple btn-simple--accent',
  MelroseOutlined = 'btn-melrose btn-melrose--outlined',
  SkyOutlined = 'btn-sky btn-sky--outlined',
  DawnOutlined = 'btn-dawn btn-dawn--outlined',
  Clear = 'btn-clear',
  Green = 'btn-green',
}

export enum ButtonSizes {
  L = 'btn--l',
  M = 'btn--m',
  S = 'btn--s',
  XS = 'btn--xs',
}

export enum ButtonPadding {
  P48 = 'btn--p-48',
  P16 = 'btn--p-16',
  P12 = 'btn--p-12',
  P8 = 'btn--p-8',
  P0 = 'btn--p-0',
}

export enum ButtonBorderRadius {
  R0 = 'btn--r-0',
  R2 = 'btn--r-2',
  R4 = 'btn--r-4',
  R8 = 'btn--r-8',
  R48 = 'btn--r-48',
  R160 = 'btn--r-160',
}

export enum ButtonShadows {
  S0 = 'btn--s-0',
  S1 = 'btn--s-1',
  S2 = 'btn--s-2',
  S3 = 'btn--s-3',
  S4 = 'btn--s-4',
}

type ButtonProps = React.HTMLProps<HTMLButtonElement> & {
  btnSize?: ButtonSizes;
  buttonType?: 'submit' | 'button' | 'reset' | undefined;
  children: JSX.Element | string;
  fullWidth?: boolean;
  underline?: boolean;
  onClick?: (event: React.MouseEvent<HTMLElement>) => void;
  padding?: ButtonPadding;
  prefixEl?: JSX.Element;
  radius?: ButtonBorderRadius;
  shadow?: ButtonShadows;
  suffixEl?: JSX.Element;
  type?: ButtonTypes;
  dataTestId?: string;
};

export type Ref = HTMLButtonElement;

const Button = forwardRef<Ref, ButtonProps>(
  (
    {
      btnSize = ButtonSizes.M,
      buttonType = 'submit',
      children,
      className,
      fullWidth,
      padding = ButtonPadding.P12,
      prefixEl,
      radius = ButtonBorderRadius.R2,
      shadow = ButtonShadows.S0,
      underline = false,
      suffixEl,
      type = ButtonTypes.Primary,
      dataTestId,
      ...props
    },
    ref,
  ) => {
    const classes = classnames('btn', type, btnSize, padding, radius, shadow, className, {
      ['btn__fullWidth']: fullWidth,
      ['btn__underline']: underline,
    });

    return (
      <button ref={ref} className={classes} type={buttonType} data-testid={dataTestId} {...props}>
        {prefixEl ? <span className='btn__prefix'>{prefixEl}</span> : null}
        {children}
        {suffixEl ? <span className='btn__suffix'>{suffixEl}</span> : null}
      </button>
    );
  },
);

Button.displayName = 'Button';

export default Button;
