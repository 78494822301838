import React, { forwardRef, useCallback, useEffect, useState } from 'react';

import { useDuration, useVideoPlayerPlayingMode } from 'shared/components/video-player';

import { TimeRulerEffectiveTime } from './time-ruler-effective-time';
import { TimeRulerFullTime } from './time-ruler-full-time';
import styles from './TimeRuler.module.scss';
import { Clip } from '../../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';

type Props = {
  episodesClips: Clip[];
  recordingId: string;
};

export const TimeRuler = React.memo(
  forwardRef(({ episodesClips, recordingId }: Props, ref: any) => {
    const [endTime, setEndTime] = useState(0);
    const duration = useDuration();
    const { useEffectiveTime: effectiveTime } = useVideoPlayerPlayingMode();

    const handleContainerResize = useCallback(() => {
      if (!ref.current || !ref.current) return;
      setEndTime(duration);
    }, [ref, duration]);

    useEffect(() => {
      if (ref?.current !== null) {
        const observer = new ResizeObserver(handleContainerResize);

        const containerElement: HTMLElement = ref.current;
        observer.observe(containerElement);

        return () => {
          observer.unobserve(containerElement);
        };
      }
    }, [ref, handleContainerResize]);

    return (
      <div ref={ref} className={styles.timeRuler} data-timeline-element='ruler'>
        {effectiveTime ? (
          <TimeRulerEffectiveTime episodesClips={episodesClips} recordingId={recordingId} />
        ) : (
          <TimeRulerFullTime endTime={endTime} recordingId={recordingId} />
        )}
      </div>
    );
  }),
);

TimeRuler.displayName = 'TimeRuler';
