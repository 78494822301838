import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import trim from 'lodash/trim';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDuplicatePlaylist } from 'api/playlist/useDuplicatePlaylist';
import Button, { ButtonTypes } from 'shared/components/button';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';
import Input from 'shared/components/input';
import Spinner from 'shared/components/spinner';
import { Playlist } from 'shared/types';

import styles from './DuplicatePlaylistDialog.module.scss';

export interface Props {
  onCancel?: () => void;
  isOpen: boolean;
  onClose: () => void;
  onUpdate?: () => void;
  playlist: Playlist;
}

const DuplicatePlaylistDialog = ({ onCancel, isOpen, onClose, playlist, onUpdate }: Props) => {
  const { t } = useTranslation();
  const { duplicatePlaylist, isSuccess, isError, isLoading } = useDuplicatePlaylist({ playlistId: playlist.id });

  useEffect(() => {
    if (isSuccess) {
      onUpdate && onUpdate();
      onClose();
    }

    if (isError) {
      onClose();
    }
    // TODO fix array of dependencies
  }, [isSuccess, isError]);

  const nameGenerator = (name: string) => t('recording-playlists:duplicate-form.default-name-template', { name });

  const [name, setName] = useState<string>(nameGenerator(playlist.name));

  const handleCancel = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    if (onCancel) onCancel();
    onClose();
  };

  const handleSubmit = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    duplicatePlaylist({ name });

    return false;
  };

  const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleCancel}
      aria-labelledby='form-dialog-title'
      aria-describedby='form-dialog-description'
    >
      <form onSubmit={(event) => event.preventDefault()} onClick={(event) => event.stopPropagation()}>
        <div className={styles.dialog}>
          <DialogTitle id='form-dialog-title'>{t('recording-playlists:duplicate-form.title')}</DialogTitle>
          <DialogContent>
            <Input autoSelect autoFocus defaultValue={name} id='edit-playlist-name' onChange={handleNameChange} />
          </DialogContent>
          <DialogActions className={styles.dialogActions}>
            <Button buttonType={'button'} disabled={isLoading} onClick={handleCancel} type={ButtonTypes.Tertiary}>
              {t('common:actions.cancel')}
            </Button>
            <Button
              buttonType={'submit'}
              onClick={handleSubmit}
              type={ButtonTypes.Primary}
              disabled={trim(name).length === 0}
              dataTestId='duplicate-playlist-dialog-confirm-button'
            >
              {isLoading ? <Spinner size={IconSizes.small} color={IconColors.white} /> : t('common:actions.duplicate')}
            </Button>
          </DialogActions>
        </div>
      </form>
    </Dialog>
  );
};

export default DuplicatePlaylistDialog;
