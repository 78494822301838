import { Stack, Tooltip } from '@mui/material';
import classNames from 'classnames';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { Chevron } from 'shared/components/chevron';
import { IconPin } from 'shared/components/icons/icon-pin';
import { IconPinSolid } from 'shared/components/icons/icon-pin-solid';
import { IconColors, IconSizes } from 'shared/components/icons/svg-icon';

import { useRowGroupIsCollapsed, useSetRowGroupIsCollapsed } from '../../store/atoms';
import { RowGroupList } from '../row-group-list';
import { RowHeaderContent } from '../row-header-content';
import styles from '../Rows.module.scss';
import { RowGroup } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/row';

type Props = {
  rowsGroup: RowGroup;
  level: number;
  recordingId: string;
  onPin?: () => void;
  isPinned?: boolean;
};

export const RowsHeadersGroup = ({ rowsGroup, level, recordingId, onPin, isPinned }: Props) => {
  const { t } = useTranslation();
  const isCollapsed = useRowGroupIsCollapsed(rowsGroup.id);
  const setRowIsCollapsed = useSetRowGroupIsCollapsed(rowsGroup.id);

  const handleCollapse = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setRowIsCollapsed(!isCollapsed);
    },
    [isCollapsed, setRowIsCollapsed],
  );

  const handleOnPin = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      onPin && onPin();
    },
    [onPin],
  );

  return (
    <div
      className={classNames({
        [styles.shadow]: isPinned,
      })}
    >
      <div
        onClick={handleCollapse}
        className={classNames(styles.rowGroupHeaderContainer, styles[`level-${level}`], styles.clickable)}
      >
        <RowHeaderContent title={onPin ? '' : t(rowsGroup.title)}>
          <Stack direction={'row'} alignItems={'center'} gap={0.5}>
            <span>{t(rowsGroup.title)}</span>
            {onPin ? (
              <Tooltip
                enterDelay={500}
                placement='right'
                title={
                  isPinned
                    ? t('timeline:tooltips.unpin-possession-scenarios')
                    : t('timeline:tooltips.pin-possession-scenarios')
                }
              >
                <span onClick={handleOnPin}>
                  {isPinned ? (
                    <IconPinSolid isButton size={IconSizes.xsmall} />
                  ) : (
                    <IconPin color={IconColors.storm} isButton size={IconSizes.xsmall} />
                  )}
                </span>
              </Tooltip>
            ) : null}
          </Stack>
        </RowHeaderContent>
        <Chevron isOpen={!isCollapsed} color={IconColors.night} />
      </div>

      <div style={{ display: isCollapsed ? 'none' : 'block' }}>
        <RowGroupList recordingId={recordingId} rowsGroup={rowsGroup} />
      </div>
    </div>
  );
};
