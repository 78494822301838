import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { useAddManyToPlaylist } from 'api/playlist/useAddManyToPlaylist';
import { invalidatePlaylistQuery } from 'api/playlist/usePlaylist';
import { TacticalAnalysesFilters } from 'api/recording/useEpisodesWithFilters/types';
import { useTacticalAnalysesPlaylistItems } from 'api/recording/useEpisodesWithFilters/useTacticalAnalysesPlaylistItems';
import { useVideoPlayerActions } from 'shared/components/video-player';
import { Playlist } from 'shared/types';
import { RecordingsFilters } from 'shared/types/recording/types';

import { PostNewPlaylistItem } from '../../../../../../api/playlist/types';
import { useMapVideos } from '../../../../util/map-videos';
import { MultiAnalysisFilters } from '../multi-analysis-filters';

interface Props {
  appliedFilters: RecordingsFilters | undefined;
  filtersData: TacticalAnalysesFilters;
  isLoadingRecordings: boolean;
  onClose: () => void;
  onUpdate: (filters: RecordingsFilters) => void;
  playlistId: string;
}

export const MultiAnalysisFiltersPlaylist = ({
  appliedFilters,
  filtersData,
  isLoadingRecordings,
  onClose,
  onUpdate,
  playlistId,
}: Props) => {
  const { t } = useTranslation();
  const mapVideos = useMapVideos();
  const actions = useVideoPlayerActions();

  const { addManyToPlaylist, isLoading: isAddManyToPlaylistLoading } = useAddManyToPlaylist();

  const { isLoading: isLoadingFilteredData, getFilteredItems, data } = useTacticalAnalysesPlaylistItems();

  const addPlaylistItemsToPlaylist = useCallback(() => {
    if (!(data.length > 0)) return;
    const playlistItems: PostNewPlaylistItem[] = data
      .filter((playlistItem) => playlistItem.origin)
      .map((playlistItem) => {
        return {
          endTime: playlistItem.endTime,
          name: playlistItem.name || '',
          playlistId,
          startTime: playlistItem.startTime,
          recordingId: playlistItem.recordingId,
          fundamentalsSelected: playlistItem.fundamentalsSelected,
        };
      });
    addManyToPlaylist({
      items: playlistItems,
      options: {
        onSuccess: (response: Playlist) => {
          const playlistItems = mapVideos(response);
          actions.replacePlaylistItems(playlistItems);
          invalidatePlaylistQuery();
          onClose();
        },
      },
    });
  }, [mapVideos, onClose, actions, data, addManyToPlaylist, playlistId]);

  return (
    <MultiAnalysisFilters
      appliedFilters={appliedFilters}
      applyButtonLabel={t('recordings:filters.add-clips', { count: data.length ?? 0 })}
      filtersData={filtersData}
      getFilteredItems={getFilteredItems}
      isDisabled={data.length === 0}
      isLoading={isLoadingFilteredData || isAddManyToPlaylistLoading}
      isLoadingFilters={isLoadingRecordings}
      loadingButtonLabel={t('common:searching').toUpperCase()}
      onApply={addPlaylistItemsToPlaylist}
      onUpdate={onUpdate}
      recordingIds={appliedFilters?.recordingIds ?? []}
    />
  );
};
