import classNames from 'classnames';
import { forwardRef, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import styles from './ClipEpisode.module.scss';
import { clipAtomFamily } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/atoms';
import { TIMELINE_CONFIG } from '../../../config';
import timelineStyles from '../../TimelineTable.module.scss';
import { CSS_CLIP_ZOOM_WIDTH } from '../timeline-css-variables';
import { Clip } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';

type Props = {
  clip: Clip;
  recordingId: string;
};

const EpisodeName = forwardRef(({ children, isVisible }: { children: ReactNode; isVisible: boolean }, ref: any) => (
  <div ref={ref} className={classNames(styles.episodeName, { [styles.isVisible]: isVisible })}>
    {children}
  </div>
));

EpisodeName.displayName = 'EpisodeName';

export const ClipEpisode = ({ clip }: Props) => {
  const { t } = useTranslation();
  const [showShortName, setShowShortName] = useState(false);
  const [showName, setShowName] = useState(true);
  const nameRef = useRef<HTMLDivElement>(null);
  const boxRef = useRef<HTMLDivElement>(null);

  const clipWithSelection = useRecoilValue(clipAtomFamily(clip.id));

  const handleContainerResize = useCallback((entries: ResizeObserverEntry[]) => {
    if (!nameRef.current || !boxRef.current) return;
    const entry = entries[0].target as HTMLDivElement;
    const hasTextOverflow = entry.offsetWidth < nameRef.current.scrollWidth;
    setShowShortName(hasTextOverflow);
    setShowName(entry.clientWidth > 20);
  }, []);

  useEffect(() => {
    if (boxRef?.current !== null) {
      const observer = new ResizeObserver(handleContainerResize);

      const containerElement: HTMLElement = boxRef.current;
      observer.observe(containerElement);

      return () => {
        observer.unobserve(containerElement);
      };
    }
  }, [handleContainerResize]);

  const clipStyle = useMemo(
    () => ({
      height: `${TIMELINE_CONFIG.EPISODE_ROW_HEIGHT}px`,
      width: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})`,
    }),
    [clip],
  );

  return (
    <div
      ref={boxRef}
      title={t(clip.title, { name: clip.name })}
      className={styles.episodeClipContainer}
      style={clipStyle}
    >
      <div
        className={classNames(timelineStyles.clip, styles.episodeClip, {
          [styles.isSelected]: clipWithSelection.isSelected,
          [timelineStyles.isSelectedForPlaying]: clipWithSelection.isSelectedForPlaying,
        })}
        data-clip-id={clip.id}
        data-clip-start-time={clip.startTime}
        data-is-selected={clipWithSelection.isSelected}
      >
        <EpisodeName isVisible={showName && showShortName}>{clip.name}</EpisodeName>
        <EpisodeName ref={nameRef} isVisible={showName && !showShortName}>
          {t(clip.title, { name: clip.name })}
        </EpisodeName>
      </div>
    </div>
  );
};
