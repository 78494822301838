import classNames from 'classnames';
import React, { forwardRef, PropsWithChildren, ReactNode } from 'react';

import styles from './RowsContentContanier.module.scss';
import { usePinScenarios } from '../../../../../hooks/use-pin-scenarios';
import { RowGroups } from '../row-groups';
import { RowGroup } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/row';

export const RowsContentContainer = forwardRef(
  (
    {
      scenariosRow,
      stickComponents,
      contentRowGroups,
      recordingId,
    }: PropsWithChildren<{
      scenariosRow: React.ReactNode;
      recordingId: string;
      contentRowGroups: RowGroup[];
      stickComponents: ReactNode;
    }>,
    ref: any,
  ) => {
    const pinScenarios = usePinScenarios();

    return (
      <div>
        <div ref={ref} className={classNames(styles.rowsContentHeader, { [styles.shadow]: pinScenarios })}>
          {stickComponents}
          {pinScenarios ? scenariosRow : null}
        </div>
        <div>
          {!pinScenarios ? scenariosRow : null}
          <RowGroups rowGroups={contentRowGroups} recordingId={recordingId} />
        </div>
      </div>
    );
  },
);

RowsContentContainer.displayName = 'RowsContentHeader';
