import React from 'react';

import { RowsContentGroup } from '../row-content-group';
import { RowGroup } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/row';

type Props = {
  rowGroups: RowGroup[];
  recordingId: string;
};

export const RowGroups = React.memo(({ rowGroups, recordingId }: Props) => {
  return (
    <>
      {rowGroups
        .filter((rowGroup) => rowGroup.totalClips > 0)
        .map((rowGroup) => (
          <RowsContentGroup key={rowGroup.type} level={0} rowGroup={rowGroup} recordingId={recordingId} />
        ))}
    </>
  );
});

RowGroups.displayName = 'RowGroups';
