import classNames from 'classnames';
import React, { useMemo } from 'react';

import { useVideoPlayerPlayingMode } from 'shared/components/video-player';

import styles from './EffectiveTimeSeparators.module.scss';
import { CSS_CLIP_ZOOM_WIDTH } from '../timeline-table/components/timeline-css-variables';
import { Clip } from '../../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';

type Props = {
  episodesClips: Clip[];
};

const Separator = ({ clip, isHidden }: { clip: Clip; isHidden: boolean }) => {
  return (
    <div
      key={clip.id}
      className={classNames(styles.container, { [styles.isHalfTime]: clip.title, [styles.isHidden]: isHidden })}
    >
      <div className={styles.content} key={clip.id}>
        {clip.title}
      </div>
    </div>
  );
};

const EmptySeparator = ({ clip }: { clip: Clip }) => {
  const emptyStyle = useMemo(
    () => ({ width: `calc(var(${CSS_CLIP_ZOOM_WIDTH}) * ${clip.endTime - clip.startTime})` }),
    [clip],
  );

  return <div key={clip.id} className={styles.emptySeparator} style={emptyStyle} />;
};

export const EffectiveTimeSeparators = React.memo(({ episodesClips }: Props) => {
  const { useEffectiveTime: effectiveTime } = useVideoPlayerPlayingMode();

  return effectiveTime ? (
    <div className={styles.separator}>
      {episodesClips.map((clip, idx) =>
        clip.type === 'not-effective-time' ? (
          <Separator key={clip.id} clip={clip} isHidden={idx === 0} />
        ) : (
          <EmptySeparator key={clip.id} clip={clip} />
        ),
      )}
    </div>
  ) : null;
});

EffectiveTimeSeparators.displayName = 'EffectiveTimeSeparators';
