import { literal, TypeOf, z } from 'zod';

export enum ProcessGamePipelineType {
  THIRD_PARTY_TACTICAL = 'third-party-tactical',
  THIRD_PARTY_TACTICAL_TRACKING = 'third-party-tactical-tracking',
}

export enum ProcessGameFieldsNames {
  recordingId = 'recording_id',
  videoPath = 'video_path',
  bucket = 'bucket',
  pipelineType = 'pipeline_type',
  pitchWidth = 'pitch_width',
  pitchLength = 'pitch_length',
  trackingProvider = 'tracking_provider',
  eventsProvider = 'events_provider',
  annotationTool = 'annotationTool',
  runProcessing = 'run_processing',
  restartProcessing = 'restart_processing',
}

export enum FilesChyronHegoSetOneFieldsNames {
  srmlXml = 'srml_xml',
  metadataXml = 'metadata_xml',
  trackingDat = 'tracking_dat',
}

export enum FilesChyronHegoSetTwoFieldsNames {
  matchInfoXml = 'match_info_xml',
  positionsXml = 'positions_xml',
}

export enum FilesHawkeyeFieldsNames {
  centroidsFolder = 'centroids_folder',
  ballFolder = 'ball_folder',
}

export enum FilesSecondSpectrumFieldsNames {
  dataXml = 'data_xml',
  metadataXml = 'metadata_xml',
  physicalSummaryCsv = 'physical_summary_csv',
  dataJsonl = 'data_jsonl',
  metadataJson = 'metadata_json',
}

export enum FilesSignalityFieldsNames {
  tracking1stJson = 'tracking_1st_json',
  tracking2ndJson = 'tracking_2nd_json',
  metadataJson = 'metadata_json',
}

export enum FilesOptaFieldsNames {
  f7Xml = 'f7_xml',
  f24Xml = 'f23_xml',
}

export enum FilesBundesligaFieldsNames {
  eventsXml = 'events_xml',
}

interface RecordingSettings {
  pitch: {
    length: string;
    width: string;
  };
}

export enum ProcessGameTrackingProvider {
  HAWKEYE = 'hawkeye',
  CHYRONHEGO = 'chyronhego',
  SIGNALITY = 'signality',
  SECOND_SPECTRUM = 'second-spectrum',
  SPORTLOGIQ = 'sportlogiq',
}

export enum ProcessGameEventsProvider {
  KOGNIA = 'kognia',
  OPTA = 'opta',
  BUNDESLIGA = 'bundesliga',
}

export enum ProcessGameAnnotationTool {
  CVAT = 'cvat',
  SUPER_ANNOTATE = 'super-annotate',
}

export const trackingFilesFieldsNames = {
  ...FilesChyronHegoSetTwoFieldsNames,
  ...FilesChyronHegoSetOneFieldsNames,
  ...FilesHawkeyeFieldsNames,
  ...FilesSecondSpectrumFieldsNames,
  ...FilesSignalityFieldsNames,
};

export const eventsFilesFieldsNames = {
  ...FilesOptaFieldsNames,
  ...FilesBundesligaFieldsNames,
};

export interface FilesHawkeyeForm {
  [FilesHawkeyeFieldsNames.centroidsFolder]?: string;
  [FilesHawkeyeFieldsNames.ballFolder]?: string;
}

export const filesHawkeyeSchema = z.object({
  [FilesHawkeyeFieldsNames.centroidsFolder]: z.string().optional(),
  [FilesHawkeyeFieldsNames.ballFolder]: z.string().optional(),
});

export interface FilesChyronHegoSetOneForm {
  [FilesChyronHegoSetOneFieldsNames.srmlXml]?: string;
  [FilesChyronHegoSetOneFieldsNames.metadataXml]?: string;
  [FilesChyronHegoSetOneFieldsNames.trackingDat]?: string;
}

export const filesChyronHegoSetOneSchema = z.object({
  [FilesChyronHegoSetOneFieldsNames.srmlXml]: z.string().optional(),
  [FilesChyronHegoSetOneFieldsNames.metadataXml]: z.string().optional(),
  [FilesChyronHegoSetOneFieldsNames.trackingDat]: z.string().optional(),
});

export interface FilesChyronHegoSetTwoForm {
  [FilesChyronHegoSetTwoFieldsNames.matchInfoXml]?: string;
  [FilesChyronHegoSetTwoFieldsNames.positionsXml]?: string;
}

export const filesChyronHegoSetTwoSchema = z.object({
  [FilesChyronHegoSetTwoFieldsNames.matchInfoXml]: z.string().optional(),
  [FilesChyronHegoSetTwoFieldsNames.positionsXml]: z.string().optional(),
});

export interface FilesSecondSpectrumSetOneForm {
  [FilesSecondSpectrumFieldsNames.dataXml]?: string;
  [FilesSecondSpectrumFieldsNames.metadataXml]?: string;
  [FilesSecondSpectrumFieldsNames.physicalSummaryCsv]?: string;
}

export const filesSecondSpectrumSetOneSchema = z.object({
  [FilesSecondSpectrumFieldsNames.dataXml]: z.string().optional(),
  [FilesSecondSpectrumFieldsNames.metadataXml]: z.string().optional(),
  [FilesSecondSpectrumFieldsNames.physicalSummaryCsv]: z.string().optional(),
});

export interface FilesSecondSpectrumSetTwoForm {
  [FilesSecondSpectrumFieldsNames.dataXml]?: string;
  [FilesSecondSpectrumFieldsNames.metadataXml]?: string;
  [FilesSecondSpectrumFieldsNames.physicalSummaryCsv]?: string;
}

export const filesSecondSpectrumSetTwoSchema = z.object({
  [FilesSecondSpectrumFieldsNames.dataJsonl]: z.string().optional(),
  [FilesSecondSpectrumFieldsNames.metadataJson]: z.string().optional(),
});

export interface FilesSignalityForm {
  [FilesSignalityFieldsNames.tracking1stJson]?: string;
  [FilesSignalityFieldsNames.tracking2ndJson]?: string;
  [FilesSignalityFieldsNames.metadataJson]?: string;
}

export const filesSignalitySchema = z.object({
  [FilesSignalityFieldsNames.tracking1stJson]: z.string().optional(),
  [FilesSignalityFieldsNames.tracking2ndJson]: z.string().optional(),
  [FilesSignalityFieldsNames.metadataJson]: z.string().optional(),
});

export interface FilesOptaForm {
  [FilesOptaFieldsNames.f7Xml]?: string;
  [FilesOptaFieldsNames.f24Xml]?: string;
}

export const filesOptaSchema = z.object({
  [FilesOptaFieldsNames.f7Xml]: z.string().optional(),
  [FilesOptaFieldsNames.f24Xml]: z.string().optional(),
});

export interface FilesBundesligaForm {
  [FilesBundesligaFieldsNames.eventsXml]?: string;
}

export const filesBundesligaSchema = z.object({
  [FilesBundesligaFieldsNames.eventsXml]: z.string().optional(),
});

export interface ProcessGameForm
  extends FilesHawkeyeForm,
    FilesChyronHegoSetOneForm,
    FilesChyronHegoSetTwoForm,
    FilesSecondSpectrumSetOneForm,
    FilesSecondSpectrumSetTwoForm,
    FilesSignalityForm,
    FilesOptaForm,
    FilesBundesligaForm {
  [ProcessGameFieldsNames.recordingId]: string;
  [ProcessGameFieldsNames.videoPath]: string;
  [ProcessGameFieldsNames.bucket]: string;
  [ProcessGameFieldsNames.pipelineType]: ProcessGamePipelineType;
  [ProcessGameFieldsNames.pitchLength]: RecordingSettings['pitch']['length'];
  [ProcessGameFieldsNames.pitchWidth]: RecordingSettings['pitch']['width'];
  [ProcessGameFieldsNames.trackingProvider]?: ProcessGameTrackingProvider;
  [ProcessGameFieldsNames.eventsProvider]?: ProcessGameEventsProvider;
  [ProcessGameFieldsNames.annotationTool]: ProcessGameAnnotationTool;
  [ProcessGameFieldsNames.runProcessing]: boolean;
  [ProcessGameFieldsNames.restartProcessing]: boolean;
}

export const processGameDefaultValues = {
  [ProcessGameFieldsNames.bucket]: 'kognia-client-uploads',
  [ProcessGameFieldsNames.annotationTool]: ProcessGameAnnotationTool.CVAT,
  [ProcessGameFieldsNames.runProcessing]: true,
  [ProcessGameFieldsNames.restartProcessing]: false,
};

const filesSchema = z.object({
  ...filesHawkeyeSchema.shape,
  ...filesChyronHegoSetOneSchema.shape,
  ...filesChyronHegoSetTwoSchema.shape,
  ...filesSecondSpectrumSetOneSchema.shape,
  ...filesSecondSpectrumSetTwoSchema.shape,
  ...filesSignalitySchema.shape,
  ...filesOptaSchema.shape,
  ...filesBundesligaSchema.shape,
});

export const processGameFormSchema = z.object({
  [ProcessGameFieldsNames.recordingId]: z.string().min(2),
  [ProcessGameFieldsNames.videoPath]: z.string().min(2),
  [ProcessGameFieldsNames.bucket]: z.string(),
  [ProcessGameFieldsNames.pipelineType]: z.nativeEnum(ProcessGamePipelineType),
  [ProcessGameFieldsNames.pitchLength]: z.string().min(1, 'Pitch length is required'),
  [ProcessGameFieldsNames.pitchWidth]: z.string().min(1).min(1, 'Pitch width is required'),
  [ProcessGameFieldsNames.trackingProvider]: z
    .nativeEnum(ProcessGameTrackingProvider)
    .optional()
    .or(literal('').transform(() => undefined)),
  [ProcessGameFieldsNames.eventsProvider]: z
    .nativeEnum(ProcessGameEventsProvider)
    .optional()
    .or(literal('').transform(() => undefined)),
  [ProcessGameFieldsNames.annotationTool]: z.nativeEnum(ProcessGameAnnotationTool),
  [ProcessGameFieldsNames.runProcessing]: z.boolean(),
  [ProcessGameFieldsNames.restartProcessing]: z.boolean(),
  ...filesSchema.shape,
});

export type ProcessGameFormSchema = TypeOf<typeof processGameFormSchema>;
