import React from 'react';

import { ClipTimeline } from '../clip-timeline';
import { Clip } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/clip';

type Props = { recordingId: string; clips: Clip[] };

export const EpisodesClips = ({ recordingId, clips }: Props) => {
  return (
    <>
      {clips.map((clip) => (
        <ClipTimeline key={clip.id} recordingId={recordingId} clip={clip} />
      ))}
    </>
  );
};
