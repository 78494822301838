import React from 'react';

import { RowsHeadersGroup } from '../row-headers-group';
import { RowGroup } from '../../../../../api/use-tactical-analysis-data/generate-timeline-rows/types/row';

export const RowGroupsList = ({ rowGroups, recordingId }: { recordingId: string; rowGroups: RowGroup[] }) => {
  return (
    <div>
      {rowGroups
        .filter((rowGroup) => rowGroup.totalClips > 0)
        .map((rowGroup) => (
          <RowsHeadersGroup key={rowGroup.type} recordingId={recordingId} level={0} rowsGroup={rowGroup} />
        ))}
    </div>
  );
};
