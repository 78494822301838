import React, { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';

import Container from 'shared/components/container';
import { SidebarLayout } from 'shared/components/sidebar-layout';
import { SwitchEnvironment } from 'shared/components/switch-environment';
import { VideoPlayerStateProvider } from 'shared/components/video-player';
import { PLAYLIST_WITH_OVERLAYS_PLAYING_MODE } from 'shared/components/video-player/defaultPlayingModes';
import { useBranding } from 'shared/hooks/use-branding/useBranding';
import usePageStateMachine from 'shared/hooks/use-page-state-machine';
import { Playlist } from 'shared/types';

import { PlaylistDetailPage } from './components/playlist-detail-page';
import PlaylistDetailSkeleton from './components/playlists-detail-skeleton';
import { usePlaylistPage } from './hooks/use-playlist-page';
import { useMapVideos } from './util/map-videos';

type RouteParams = { id: string; playlistId: string };

type PlaylistDetailPageProps = {
  playlistId: string;
};

const PlaylistDetailContainer = ({ playlistId }: PlaylistDetailPageProps) => {
  const mapVideos = useMapVideos();
  const branding = useBranding();
  const { t } = useTranslation();
  const { isPageLoading, isPageReady, isInvalidClient, data } = usePageStateMachine<Playlist>(() =>
    usePlaylistPage({ playlistId }),
  );

  useEffect(() => {
    document.title = t('common:metas.title.playlist', { clientDisplayName: branding.displayName });
  }, [branding.displayName, t]);

  const playlistItems = useMemo(() => (data ? mapVideos(data) : []), [mapVideos, data]);

  if (isInvalidClient && data?.clientId) {
    return <SwitchEnvironment resourceClientsIds={[data.clientId]} />;
  }

  return (
    <SidebarLayout>
      <Container fullScreen>
        {isPageLoading && <PlaylistDetailSkeleton />}

        {isPageReady && data && (
          <VideoPlayerStateProvider
            playerId={`playlist-${playlistId}`}
            playlistItems={playlistItems}
            playingMode={PLAYLIST_WITH_OVERLAYS_PLAYING_MODE}
          >
            <PlaylistDetailPage playlist={data} />
          </VideoPlayerStateProvider>
        )}
      </Container>
    </SidebarLayout>
  );
};

const PlaylistDetail = (props: RouteComponentProps<RouteParams>) => {
  const {
    match: {
      params: { playlistId },
    },
  } = props;

  return <PlaylistDetailContainer key={playlistId} playlistId={playlistId} />;
};

export default PlaylistDetail;
